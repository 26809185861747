//##  Default Layout Settings
//
// Define layout element width and height.

// Header
$header-height:             				            				75px  !default; // header height on table and desktop view. default: 42px
$header-height-xs:             											($header-height * 2) !default; // header height on mobile view
$header-height-mobile:                                                  46px  !default; 

$header-logo-height:                                    				17px;
$header-menu-toggler-width:                             				20px; 
$header-menu-toggler-height:                            				14px;  
$header-sidebar-toggler-margin:                         				($header-height - $header-menu-toggler-height - 1px) / 2 0 0 0 !default;
$header-responsive-toggler-margin:                      				($header-height - $header-menu-toggler-height) / 2 14px 0 6px !default; 
$header-logo-margin:                                					($header-height - $header-logo-height) / 2 10px 0 10px !default;  // adjsut logo's margin top. default: -1px

$header-page-actions-margin:                                            17px 0 15px 10px;

$header-top-menu-general-item-padding:      							29px 12px 24px 12px !default;   // adjust top menu general elements padding. default: 15px 10px 7px 10px
$header-top-menu-general-item-padding-on-mobile:       					29px 8px 25px 8px !default;  // adjust top menu general elements padding mobile. default: 15px 10px 7px 10px

$header-top-menu-user-item-padding:         							25px 10px 18px 10px !default; // adjust top menu user bar element padding. default: 6px 0px 6px 6px
$header-top-menu-user-item-padding-on-mobile:         					25px 6px 18px 6px !default;  // adjust top menu user bar element padding on mobile. default: 6px 0px 6px 4px
$header-top-menu-language-item-padding:     							28px 12px 26px 12px !default;  // adjust top menu language bar element padding. default: 11px 1px 11px 5px
$header-top-menu-language-item-padding-on-mobile:						28px 6px 26px 6px !default;  // adjust top menu language bar element padding. default: 11px 0px 11px 4px
$header-top-menu-general-item-badge-top:                				24px !default;  // adjust top menu general elements badge top position. default: 8px
$header-top-menu-icon-font-size:                                        16px !default;   

$header-top-menu-user-font-size:                                        14px !default; 
$header-top-menu-user-font-weight:                                      600 !default; 
$header-top-menu-user-dropdown-link-font-size:                          14px !default;    
$header-top-menu-user-dropdown-link-font-weight:                        300 !default; 

$header-hor-menu-general-item-padding:     								13px 10px !default;  // adjust hor menu elements padding top. default: 11px 10px
$header-hor-menu-submenu-item-padding: 				 					9px 10px !default;  // adjust hor menu elements padding top. default: 7px 10px
$header-hor-menu-submenu-item-arrow-top:                				9px;  // adjust hor menu dropdown menu's arrow. default: 9px     
$header-hor-menu-search-toggler-button-padding:      					15px 22px 15px 22px !default;  // adjust hor menu elements padding top. default: 13px 22px 11px 22px
$header-hor-menu-search-button-padding:     		 					7px 20px !default;  // adjust hor menu elements padding top. default: 5px 20px
$header-hor-menu-search-input-margin:    			 					7px 0 0 0 !default;  // adjust hor menu elements padding top. default: 5px 0 0 0

// Horizontal menu font
$header-hor-menu-default-link-font-size:                                14px !default;
$header-hor-menu-default-link-font-weight:                              normal !default;
$header-hor-menu-dropdown-menu-link-font-size:                          14px !default;
$header-hor-menu-dropdown-menu-link-font-weight:                        normal !default;
$header-hor-menu-mega-menu-header-font-size:                            15px !default;   
$header-hor-menu-mega-menu-header-font-weight:                          normal !default;    

// Sidebar
$sidebar-width:             											235px !default; // sidebar width. default:235px
$sidebar-collapsed-width:   											54px  !default; // minimized/collapsed sidebar width. default:35px
$sidebar-collapsed-submenu-width-on-hover:								($sidebar-width - 1) !default; // sub menu width displayed on hover in collapsed sidebar mode. default:210px
$sidebar-logo-container-width:  										($sidebar-width + 30) !default; // logo container width. Normally same with sidebar-width.
$sidebar-logo-container-width-xs: 										110px !default; // logo container width in mobile portrait view. default:110px.
$sidebar-logo-container-width-xxs: 										100px !default; // logo container width in mobile landspace view. default:100px.

$sidebar-menu-link-font-size:                                           14px !default;
$sidebar-menu-link-font-weight:                                         400 !default;        
$sidebar-menu-link-icon-font-size:                                      18px !default;
$sidebar-menu-link-arrow-font-size:                                     13px !default;
$sidebar-menu-sub-menu-link-icon-font-size:                             16px !default;

// Page content
$page-content-min-height:                   							600px !default; // default page content's min height. default:600px.

// Quick Sidebar
$quick-sidebar-width:                                                   320px !default;  // quick sidebar width. default: 245px;  
$quick-sidebar-tab-item-padding:										15px 15px;       // quick sidebar item padding. default: 15px 15px;

$quick-sidebar-bg-color:												#21282e; 
$quick-sidebar-font-color:												lighten($quick-sidebar-bg-color, 40%);

// Page footer
$page-footer-height:                                                    33px !default;

// Z-index master list
$zindex-header-fixed:                                                   9995 !default;
$zindex-header-static:                                                  9995 !default;
$zindex-quick-sidebar:                                                  9997 !default;
$zindex-quick-sidebar-full-height:										9996 !default;
$zindex-sidebar-fixed:     												10000 !default; 
$zindex-footer-fixed:      												10000 !default;
$zindex-sidebar-submenu:   												2000 !default; 
$zindex-go-to-top:   												    10001 !default; 

//## Layout image  paths
$layout-img-path:           											'../img/' !default;
$layout-theme-img-path:           										'../../img/' !default;